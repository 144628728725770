<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length > 0">
                    <div class="col pl-0" :class="{'pr-0': platform == 'mobile'}">
                        <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="pl-0" :class="platform == 'desktop' ? 'col-auto' : 'col-12 pr-0'">
                        <div class="btn-group w-100 alphabetic" :class="{'mb-3': platform == 'mobile'}">
                            <button type="button" class="btn btn-info btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span><i class="fas fa-sort-alpha-up mr-2"></i>Filtrar</span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item text-center" type="button" @click="filterAz('all')"><i class="fas fa-filter mr-2"></i>Todos</button>
                                <div class="dropdown-divider"></div>
                                <button class="dropdown-item text-uppercase" type="button" v-for="(letter, index) in alphabetic" @click="filterAz(letter)" :key="index">
                                    <template v-if="filter_az_active == '' || filter_az_active != letter">
                                        <i class="far fa-circle mr-2"></i>{{letter}}
                                    </template>
                                    <template v-else>
                                        <i class="far fa-dot-circle mr-2"></i>{{letter}}
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="col pl-0" data-input-subtitle="Data incial" :class="{'pr-0': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="col pl-0" data-input-subtitle="Data final" :class="{'pr-0': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                    </div>
                    <div class="w-100" v-show="platform == 'mobile'"></div>
                    <div class="col pl-0 pr-0">
                        <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="users.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Não encontramos nenhum cliente!</p>
                    </div><!-- /alert-danger -->
                    <div class="row ml-0 mt-3 mb-3 mr-0" v-show="clients.length > 0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                                <option value="" selected>Todos</option>
                                <option v-for="(item, index) in clients" :value="item.usuario" :key="index">{{item.nome}}: {{item.endereco}}</option>
                            </select>
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" type="text" placeholder="Pesquisar cliente..." v-model="searchs">
                        </div>
                    </div><!-- /row -->
                    <div class="card mb-3" v-for="(item, index) in filteredListClients" :key="index">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12 text-truncate mt-1">{{item.nome}}: {{item.endereco}}</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row pb-2">
                                <div class="col text-truncate">Gastou</div>
                                <div class="col text-truncate">{{item.gastou | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Apostas</div>
                                <div class="col text-truncate">{{item.num_apostas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Qtd. Prêmios</div>
                                <div class="col text-truncate">{{item.num_premios | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Qtd. Apostas abertas</div>
                                <div class="col text-truncate">{{item.num_apostas_abertas | formatValue}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Entradas em aberto</div>
                                <div class="col text-truncate">{{item.total_apostas_abertas | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Saldo em prêmios</div>
                                <div class="col text-truncate">{{item.total_premios | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2">
                                <div class="col text-truncate">Depósitos</div>
                                <div class="col text-truncate">{{item.depositos | formatCoin}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                <div class="col text-truncate">Retiradas</div>
                                <div class="col text-truncate">{{item.retiradas | formatCoin}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');

export default {
    data() {
        return {
            users: [],
            error: '',
            searchs: '',
            clients: [],
            user: 'all',
            date1: today,
            date2: today,
            loading: false,
            isLoading: false,
            nothing_found: false,
            filter_az_active: '',
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            alphabetic: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        search() {

            const self = this;
            const url = self.user == 'all' ? `dashboard/cash/clients/${self.date1}/between/${self.date2}/all` : `dashboard/cash/clients/${self.date1}/between/${self.date2}/user/${self.user}`;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                
                self.searchs = '';
                self.clients = [];
                self.isLoading = true;

                api.get(url).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                            self.clients = Object.keys(response.data.clients).map((key) => {
                                return response.data.clients[key];
                            }).sort((a, b) => {
                                return a.nome.localeCompare(b.nome);
                            }).filter((item) => item.gastou > 0);
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            )
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            )
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        filterAz(filter) {

            const select = document.querySelector('#virtual-select');

            if (filter == 'all') {

                this.filter_az_active = '';

                const options = this.users.map((item) => {
                    return {
                        label: item.nome,
                        value: item._id,
                        description: item.endereco
                    }
                });

                select.setOptions(options);
            } 
            else 
            {
                this.filter_az_active = filter;
                const users = this.users.filter((item) => this.removeAccents(item.nome).toLowerCase().substr(0, 1) == filter);

                if (users.length == 0) {
                    Swal.fire(
                        'Atenção!',
                        `Não encontramos nenhum cliente com a letra inicial <strong class="text-uppercase">${filter}</strong>.`,
                        'warning'
                    );
                } else {

                    const options = users.map((item) => {
                        return {
                            label: item.nome,
                            value: item._id,
                            description: item.endereco
                        }
                    });

                    select.setOptions(options);
                }
            }
        },
        searchUser(event) {
            this.searchs = event.target.value;
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
    },
    watch: {
        users(items) {
            
            var total_items = items.length;

            if (total_items) {
                window.VirtualSelect.init({
                    ele: '#virtual-select',
                    search: true,
                    placeholder: 'Selecione um cliente',
                    noOptionsText: 'Nenhuma opção disponível',
                    noSearchResultsText: 'Nenhum resultado encontrado',
                    searchPlaceholderText: 'Buscar cliente...',
                    hasOptionDescription: true,
                    options: this.users.map((item) => {
                        return {
                            label: item.nome,
                            value: item._id,
                            description: item.endereco
                        }
                    })
                });
            }
        }
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    },
    computed: {
        filteredListClients() {
            return this.clients.filter((item) => {
                return item.nome.toLowerCase().trim().includes(this.searchs.toLowerCase().trim()) || item.usuario.toLowerCase().trim().includes(this.searchs.toLowerCase().trim())
            });
        }
    },
    mounted() {

        const self = this;

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                if (this.value) {
                    self.user = this.value;
                } else {
                    self.user = 'all';
                }
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;
        self.nothing_found = false;

        api.get('dashboard/type/users/4').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.users = response.data.users.sort((a, b) => {
                        return this.removeAccents(a.nome).localeCompare(this.removeAccents(b.nome), 'fr', {
                            ignorePunctuation: true
                        });
                    });
                break;
                case 'nothing_found':
                    self.nothing_found = true;
                break;
                default:
                    self.error = 'Ocorreu um erro, tente novamente!';
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.col.pl-0 #virtual-select {
    max-width: 100%!important;
}
</style>